<template>
  <div class="relative home">
    <a href="#" v-scroll-to="'#element'" class="fixed bottom-0 right-0 mb-20 mr-4 bg-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 10h3l-4-4-4 4h3v4h2v-4z" fill="rgba(0,50,100,1)"/></svg>
    </a>
    <a href="https://wa.me/6562421637" class="fixed bottom-0 right-0 mb-4 mr-4 bg-white rounded-full" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z" fill="rgba(40,200,100,1)"/></svg>
    </a>
    <div id="element" class="site-header">
        <div class="container flex items-center justify-between max-w-screen-lg px-2 py-4 mx-auto sm:flex-row sm:px-4">
            <h1 class="block"><img class="w-auto mx-auto" src="images/logo.png"></h1>
            <div class="flex flex-col items-center text-right">
                <p class="text-sm leading-5 md:mr-2">Call us now at</p>
                <a href="tel:92749872" class="text-lg font-bold text-siteBlue lg:text-xl"><i class="fas fa-phone-alt"></i> <span>9274 9872</span></a>
            </div>
        </div>
        <div class="container max-w-screen-lg px-2 pt-2 pb-4 mx-auto">
          <div class="flex justify-center">
            <a href="#about-us" class="mx-2 text-sm md:text-base md:mx-4">About Us</a>
            <a href="#products" class="mx-2 text-sm md:text-base md:mx-4">Products</a>
            <a href="#reviews" class="mx-2 text-sm md:text-base md:mx-4">Reviews</a>
            <a href="#locate-us" class="mx-2 text-sm md:text-base md:mx-4">Locate Us</a>
          </div>
        </div>
    </div>
    <div class="flex items-center justify-center text-center bg-center bg-no-repeat bg-cover site-banner">
      <div class="container max-w-screen-lg px-4 py-4 mx-auto">
        <div class="inline-block p-6 banner-text">
          <h2 class="mb-2 text-3xl font-bold leading-tight text-white uppercase lg:text-5xl xl:text-6xl xl:leading-none">Quick Response & Trustworthy</h2>
          <h4 class="text-lg uppercase lg:text-3xl xl:text-4xl text-siteLBlue xl:font-medium">Aluminium Folding Material Specialist</h4>
        </div>
      </div>
    </div>
    <div id="products" class="text-center bg-bottom bg-no-repeat site-projects">
      <div class="container max-w-screen-lg px-4 pt-12 pb-4 mx-auto xl:py-16 xl:pb-8">
        <h3 class="mb-4 text-2xl font-bold leading-tight uppercase lg:text-4xl lg:mb-8">Project Completed</h3>
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-01.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-02.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-03.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-04.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-05.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-06.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-07.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-08.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-09.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-10.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-11.jpg">
          </div>
          <div class="w-1/2 px-4 mb-8 overflow-hidden md:w-1/3 lg:w-1/4">
            <img src="images/img-project-12.jpg">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center text-white bg-center bg-no-repeat bg-cover site-form-top">
      <div class="container max-w-screen-lg px-4 py-12 mx-auto lg:py-12 xl:py-16">
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden md:w-1/2">
            <div class="p-6 bg-siteBlue lg:p-8">
              <h4 class="mb-4 text-xl leading-tight text-center lg:text-2xl">Contact Us Today For Assistance </h4>
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5722357.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center site-expertise">
      <div class="container max-w-screen-lg px-4 py-12 mx-auto lg:py-12 xl:py-16">
        <h3 class="mb-4 text-2xl font-bold leading-tight uppercase lg:text-4xl lg:mb-8">Our Expertise</h3>
        <div class="flex flex-wrap -mx-2 overflow-hidden xl:-mx-3">
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Sliding Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Sliding Window</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Grilles</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Gates</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Fences</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Railings</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Shower Screens</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Swing / Sliding Glass Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Glass Panels</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Bi-fold Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Top-Roller Folding Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Folding Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Folding Windows</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Soundproof Windows</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Soundproof Doors</div>
          </div>
          <div class="w-full px-2 my-2 overflow-hidden sm:w-1/2 md:w-1/3 xl:my-3 xl:px-3">
            <div class="w-full px-4 py-2 text-base font-medium leading-5 border-2 border-solid rounded-full border-siteBlue">Bedroom and Toilet Doors, etc</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-white bg-center bg-no-repeat bg-cover site-parnter bg-siteBlue">
      <div class="container max-w-screen-lg px-4 mx-auto md:pt-6">
        <div class="flex flex-wrap overflow-hidden">
          <div class="relative hidden w-full overflow-hidden md:w-2/3 md:block">
            <div class="absolute w-full md:pr-8">
              <img src="/images/img-partner.png" class="w-full">
            </div>
          </div>
          <div class="w-full py-12 overflow-hidden md:w-1/3 lg:py-20 xl:py-24">
            <h4 class="text-3xl font-medium leading-none uppercase xl:text-4xl">Your Right</h4>
            <h2 class="mb-4 text-5xl font-bold leading-none uppercase xl:text-6xl xl:leading-none xl:mb-8">Choice</h2>
            <ul class="pl-5 list-disc">
              <li class="mb-2 text-xl leading-tight xl:mb-4">More Than 20 Years History</li>
              <li class="mb-2 text-xl leading-tight xl:mb-4">Self Manufactured</li>
              <li class="mb-2 text-xl leading-tight xl:mb-4">Installation Provided</li>
              <li class="mb-2 text-xl leading-tight xl:mb-4">Fast Response Time</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="about-us" class="bg-center bg-no-repeat bg-cover site-about">
      <div class="container max-w-screen-lg px-4 py-12 mx-auto lg:py-12 xl:py-16">
        <div class="flex flex-wrap -mx-3 overflow-hidden xl:-mx-3">
          <div class="w-full overflow-hidden sm:my-3 sm:px-3 sm:w-1/2 md:my-4 md:px-4">
            <img class="w-full mb-6 rounded md:mb-0" src="/images/img-about.jpg">
          </div>
          <div class="w-full overflow-hidden sm:my-3 sm:px-3 sm:w-1/2 md:my-4 md:px-4">
            <h3 class="mb-4 text-2xl font-bold leading-tight uppercase lg:text-4xl lg:mb-6">About Us</h3>
            <p class="mb-4 text-base leading-7">Welcome to Hoe Heng Aluminium & Engrg Pte Ltd.</p>
            <p class="mb-4 text-base leading-7">Hoe Heng Aluminium & Engrg Pte Ltd started its humble beginnings in 1999.</p>
            <p class="mb-4 text-base leading-7">We manufacture & install custom-made aluminium folding materials.</p>
            <p class="mb-4 text-base leading-7">Over the years, to meet with the demands of the changing economy and cater to our customers' needs, we have evolved to offer an integrated packaging service to customers.</p>
          </div>
        </div>
      </div>
    </div>
    <div id="reviews" class="text-center text-white bg-siteDBlue">
      <div class="container max-w-screen-md px-4 py-12 mx-auto lg:py-12 xl:py-16">
        <h3 class="mb-4 text-2xl font-bold leading-tight uppercase lg:text-4xl lg:mb-6">What Customers Say About Us</h3>
        <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="false" pagination-padding="10">
          <slide>
            <p class="mb-4"><i>"The workmanship of the sliding door was great & it blends in perfectly with our house designs."</i></p>
            <p><b>- Mr Mohd Afiq (Condo)</b></p>
          </slide>
          <slide>
            <p class="mb-4"><i>"Great Services! The window grill was done nicely & the staff are very careful with our surroundings."</i></p>
            <p><b>- Ms Krishna (HDB)</b></p>
          </slide>
          <slide>
            <p class="mb-4"><i>"The installation was done seamlessly, the quality of the Bifold doors was exceptional strong & durable. Great Job, Chen Kai."</i></p>
            <p><b>- Mr Lim Siow Heng (Landed)</b></p>
          </slide>
          <slide>
            <p class="mb-4"><i>"The staff was very responsive to my enquires & I never regret choosing Hoe Heng Aluminium as to renovate my house. I’m very satisfised with my house now, Thank you!"</i></p>
            <p><b>- Ms Alexandra (Landed)</b></p>
          </slide>
        </carousel>
      </div>
    </div>
    <div id="locate-us" class="text-white bg-center bg-no-repeat bg-cover site-form-bottom">
      <div class="container max-w-screen-lg px-4 py-12 mx-auto lg:py-12 xl:py-16">
        <div class="flex flex-wrap items-end overflow-hidden sm:-mx-3 md:-mx-4">
          <div class="w-full overflow-hidden sm:my-3 sm:px-3 sm:w-1/2 md:my-4 md:px-4">
            <h3 class="mb-4 text-2xl font-bold leading-tight uppercase lg:text-4xl lg:mb-8">Trusted Aluminum Folding Specialist</h3>
            <h6 class="text-base leading-tight lg:text-lg xl:text-lg">You are welcome to call us at</h6>
            <h3 class="mb-8 text-2xl leading-tight lg:text-3xl">Call Us Now <a href="tel:92749872" class="font-bold ">(65) 9274 9872</a></h3>
            <h6 class="mb-2 text-base font-bold leading-tight uppercase lg:text-lg xl:text-lg">Locate Us</h6>
            <p class="mb-4 text-base">3018 Bedok North Street 5,<br>#06-04 Eastlink Singapore,<br>Singapore 486132</p>
            <p class="mb-8 text-base">
              Tel: <a href="tel:+6562421637">6242 1637</a><br>
              Mon - Sat: 8:30am - 5:30pm<br>
              Sun: Closed
            </p>
            <div class="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.3672485410548!2d103.94674845602857!3d1.3355191990259416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d3a82d21675%3A0x6328cb70b61c310a!2sHoe%20Heng%20Aluminium%20%26%20Engrg%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1605600924522!5m2!1sen!2ssg" width="100%" height="306" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
          <div class="w-full overflow-hidden sm:my-3 sm:px-3 sm:w-1/2 md:my-4 md:px-4">
            <div class="p-6 bg-siteBlue form-bottom lg:p-8">
              <h4 class="mb-4 text-xl leading-tight text-center lg:text-2xl">Contact Us Today For Assistance </h4>
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5722357.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .site-banner {
    background-image: url(/images/img-banner.jpg);
    height: 100vw;
  }
  .banner-text {
    background-color: rgba(0,0,0,0.67);
  }
  @media (min-width: 640px) {
    .site-banner {
      height: 33.33vh;
      min-height: 320px;
    }
  }
  @media (min-width: 1024px) {
    .site-banner {
      height: 66.67vh;
      min-height: 640px;
    }
  }
  .site-form-top {
    background-image: url(/images/img-bg-form-01.jpg);
  }
  .site-form-top iframe, .form-bottom iframe {
    height: 520px !important;
  }
  .site-parnter {
    background-image: url(/images/img-bg-partner.jpg);
  }
  .site-form-bottom {
    background-image: url(/images/img-bg-form-02.jpg);
  }
</style>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  export default {
    components: {
      Carousel,
      Slide
    }
  }
</script>