import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { vsCollapse } from 'vuesax'
import VueCarousel from 'vue-carousel'
import 'vuesax/dist/vuesax.css'
import './assets/tailwind.css'
import 'material-icons/iconfont/material-icons.scss'

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)
Vue.use(VueCarousel)
Vue.use(vsCollapse)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
